// This file can be replaced during build by using the `fileReplacements` array.
// `ng build` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false,
  apiScheme: "https",
  apiBaseUrl: "devapi.myvisitmaker.net",
  apiSubBaseUrl: "devsubsapi.myvisitmaker.net",
  domain:".myvisitmaker.net",
  secretKey:"IBMVMIJN",
  firebase : {
    apiKey: "AIzaSyB1n0iRlQFRK-_uMw81xh4YsZYZDtDv7sM",
    authDomain: "my-visit-maker-dev.firebaseapp.com",
    projectId: "my-visit-maker-dev",
    storageBucket: "my-visit-maker-dev.appspot.com",
    messagingSenderId: "159773223815",
    appId: "1:159773223815:web:edef35b984b827d2e84122",
    measurementId: "G-5QVQFZEKYK"
  },
  pagingInfo:{
    pageSizeConstant: 20,
    pageNoConstant: 1
  }
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/plugins/zone-error';  // Included with Angular CLI.
